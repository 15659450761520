module.exports = {
  data: {
    radioClass: "shell-radio",
    radioItemClass: ".radio__input",
    radioItemActiveClass: "radio-selected",

    //mantêm
    triggerBackClass: ".js-trigger-back",
    formSearchGoClass: ".shell-search-wrapper-go",
    formSearchBackClass: ".shell-search-wrapper-back",
    formSearchInputBackClass: ".js-input-data-volta",
    formSearchBackValClass: ".js-input-data-volta-val",
  },

  // Listen Events...
  init: function () {
    this.cacheDom();

    var self = this;

    if(!this.$radio) {
      return;
    }

    [].forEach.call(
      this.$radio.querySelectorAll(self.data.radioItemClass),
      function (item) {
        item.addEventListener("click", function (e) {
          e.stopPropagation();
          self.toggleRadioItem(item);
        });
      },
    );

    this.$formSearchBackToggle.addEventListener(
      "autofillBackHistory",
      function () {
        self.autofillBackSearchInput();
      },
    );
  },

  // Initial Autofill based on previous searches
  autofillBackSearchInput: function () {
    this.toggleRadioItem(
      this.$radio.querySelectorAll(this.data.radioItemClass)[1],
    );
  },

  // Toggle radio item visibility
  toggleRadioItem: function (currentItem) {
    var self = this;
    var activeItems = currentItem.parentElement.querySelectorAll(
      "." + this.data.radioItemActiveClass,
    );

    for (var i = 0; i < activeItems.length; i++) {
      self.uncheckTabItem(activeItems[i]);
    }

    if (!currentItem.className.indexOf(this.data.radioItemActiveClass) != -1) {
      this.checkRadioItem(currentItem);
    }
  },

  // Check radio item
  checkRadioItem: function (currentItem) {
    currentItem.classList.add(this.data.radioItemActiveClass);

    this.toggleBackSearchInput(parseInt(currentItem.dataset.option));
    currentItem.checked = true;
    document.querySelector("#form-search").dispatchEvent(window.checkForm);
  },

  // Uncheck radio item
  uncheckTabItem: function (el) {
    el.checked = false;
    el.classList.remove(this.data.radioItemActiveClass);
  },

  // Toggle back search input visibility
  toggleBackSearchInput: function (selectedIndex) {
    var onlyGo = selectedIndex == 0;
    this.$formSearchBack.classList[onlyGo ? "remove" : "add"]("is-visible");
    this.$formSearchValBack.disabled = onlyGo;
    if (onlyGo) {
      this.$formSearchInputBackClass.removeAttribute("required");
      this.$formSearchInputBackClass.setAttribute("disabled", "disabled");
    } else {
      this.$formSearchInputBackClass.setAttribute("required", "required");
      this.$formSearchInputBackClass.removeAttribute("disabled");
    }
  },

  cacheDom: function () {
    this.$radio = document.querySelector("." + this.data.radioClass);
    this.$formSearchBackToggle = document.querySelector(
      this.data.triggerBackClass,
    );
    this.$formSearchGo = document.querySelector(this.data.formSearchGoClass);
    this.$formSearchBack = document.querySelector(
      this.data.formSearchBackClass,
    );
    this.$formSearchInputBackClass = document.querySelector(
      this.data.formSearchInputBackClass,
    );
    this.$formSearchValBack = document.querySelector(
      this.data.formSearchBackValClass,
    );
  },
};
