import { HttpClient } from 'commons/ts/http.client';

interface CouponSiteBar {
  id: number;
  name: string;
  coupon: string;
  text: string;
  subText: string;
  extraText: string;
  buttonText: string;
  position: SiteElementPosition;
  page: string;
  device: string;
  url: string;
  buttonType: string;
  buttonColor: string;
  backgroundColor: string;
  textColor: string;
  delay: number;
  scroll: boolean;
  oncePerDay: boolean
}

interface SiteBarStorage {
  id: number;
  closeDate: string;
}

enum SiteElementPosition {
  TOP, BOTTOM
}

class CouponSiteBarController {
  constructor() {
    this.init();
  }
  async init() {
    await this.initGeolocation();
    const couponSiteBar: CouponSiteBar | null = await this.getCouponsSiteBar();
    if (couponSiteBar) {
      this.createCouponSitBar(couponSiteBar)
    }
  }

  async getCouponsSiteBar() {
    const cityStorage = sessionStorage.getItem('city');
    const stateStorage = sessionStorage.getItem('state');
    let page = document.location.pathname;

    const isSearchResult = !!document.getElementsByClassName("page-search-result")[0]

    if (page.includes("t/pagamento/")) {
      page = "checkout"
    }

    let device = 'mobile';
    let innerWidth = window.innerWidth;
    if (innerWidth > 768) {
      device = 'tablet';
      if (innerWidth >= 1000) {
        device = 'desktop';
      }
    }

    try {
      const response = await HttpClient.get({
        url: "/api/get-site-bar", params: {
          city: cityStorage,
          state: stateStorage,
          complement: stateStorage,
          page, device,
          isSearch: isSearchResult
        }
      });

      if (!response) {
        throw new Error('cannot find global coupon site bar');
      }
      return response;
    } catch (e) {
      console.error('cannot find coupon site bar for location');
    }
    return null;
  }

  async initGeolocation() {
    const cityStorage = sessionStorage.getItem('city');
    const state = sessionStorage.getItem('state');
    if (cityStorage || state) {
      return;
    }
    try {
      const geoRequest = await HttpClient.get({ url: "/api/localization" });
      const { city, region } = geoRequest
      if (city) {
        sessionStorage.setItem('city', city);
      }
      if (region) {
        sessionStorage.setItem('state', region);
      }
    } catch (e) {
      console.log(e);
    }
  }

  getLocalStorageData(): SiteBarStorage[] | null {
    try {
      const json = JSON.parse(localStorage.getItem('last-site-bar'));
      if (!Array.isArray(json)) {
        localStorage.removeItem('last-site-bar');
        return [];
      }
      return json;
    } catch (error) {
      localStorage.removeItem('last-site-bar');
      return [];
    }
  }

  canBeOpen(couponSiteBar: CouponSiteBar): boolean {
    const storageSitebar = this.getLocalStorageData();
    if (storageSitebar == null || storageSitebar?.length == 0) return true;
    const siteBarStorage = storageSitebar.find(e => e.id == couponSiteBar.id);
    if (!couponSiteBar.oncePerDay) {
      return !siteBarStorage;
    }
    if (!siteBarStorage) {
      return true;
    }
    const now = new Date();
    return siteBarStorage.closeDate != now.toDateString();
  }

  createCouponSitBar(couponSiteBar: CouponSiteBar | null) {
    if (!couponSiteBar) {
      return;
    }

    //prevent showing same coupon site bar
    if (!this.canBeOpen(couponSiteBar)) {
      return;
    }

    const buttonColor = couponSiteBar.buttonColor || "#071251";
    const backgroundColor = couponSiteBar.backgroundColor || "#FF7300"
    const textColor = couponSiteBar.textColor || "#FFFFFF";

    const siteBar = document.createElement('div');
    siteBar.id = "site-bar-id"
    siteBar.classList.add(`site-bar`);
    siteBar.classList.add(`position-${couponSiteBar.position.toString()}`)

    const container = document.createElement('div');
    container.classList.add(`container-sb`);
    siteBar.appendChild(container);

    const mainContainer = document.createElement('div')
    mainContainer.classList.add(`main-container-sb`);
    mainContainer.innerHTML = `
        <div class="main-container-sb-wrap">
            <p class="main-text-sb">
                ${couponSiteBar.text}
            </p>
            <p class="sub-text-sb">
                ${couponSiteBar.subText}
            </p>
        </div>`
    container.appendChild(mainContainer);

    const buttonContainer = document.createElement('div');
    buttonContainer.classList.add('button-container-sb');
    buttonContainer.id = "button-container-sb"

    let bar: string;
    bar = `
        ${couponSiteBar.buttonType === "COUPON" ? this.getButtonCoupon(couponSiteBar) : ''}
        ${couponSiteBar.buttonType === "OFFER" ? this.getButtonOffer(couponSiteBar) : ''}
        ${couponSiteBar.buttonType === "DOWNLOAD" ? this.getButtonDownload(couponSiteBar) : ''}
        <p class="extra-text-sb">
          ${couponSiteBar.extraText}
        </p>
        <div id="close-bar">
          <svg
          class="close-icon"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill=${couponSiteBar.textColor}
          xmlns="http://www.w3.org/2000/svg"
          >
          <path
            d="M14.2921 2.21615L12.8821 0.806152L7.29211 6.39615L1.70211 0.806152L0.292114 2.21615L5.88211 7.80615L0.292114 13.3962L1.70211 14.8062L7.29211 9.21615L12.8821 14.8062L14.2921 13.3962L8.70211 7.80615L14.2921 2.21615Z"
            fill=${couponSiteBar.textColor}
          />
        </svg>
        </div>
        `
    if (!bar) {
      return;
    }

    buttonContainer.innerHTML = bar;
    container.appendChild(buttonContainer);

    siteBar.innerHTML = siteBar.innerHTML + this.getStyle(backgroundColor, buttonColor, textColor)

    const insertSiteBar = () => {
      document.querySelector('body').append(siteBar);
      const copyIcon = document.getElementById("copy-icon");
      if (copyIcon) {
        copyIcon.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          var copyText = document.getElementById("coupon-value");
          navigator.clipboard.writeText(copyText.innerText);
          copyIcon.style.display = "none"
          var copiedIcon = document.getElementById("copied-icon");
          copiedIcon.style.display = "block"
        })
      }
      var icon = document.getElementById("close-bar");
      icon.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        var bar = document.getElementById("site-bar-id");
        bar.classList.add('hide-close-sb');

        try {
          let json = JSON.parse(localStorage.getItem('last-site-bar'));
          if (!Array.isArray(json)) {
            localStorage.removeItem('last-site-bar');
            json = []
          }
          const now = new Date();
          const current = json.filter(e => e?.id != couponSiteBar.id);
          current.push({
            closeDate: now.toDateString(),
            id: couponSiteBar.id
          })
          localStorage.setItem('last-site-bar', JSON.stringify(current));
        } catch (error) {
          localStorage.removeItem('last-site-bar');
        }
      })
    };

    if (couponSiteBar.delay >= 0 || couponSiteBar.delay == null)
      var delay = setTimeout(insertSiteBar, (couponSiteBar.delay || 0) * 1000)

    const listenerEventScroll = () => {
      removeEventListener("scroll", listenerEventScroll, false)
      if (delay)
        clearTimeout(delay)
      insertSiteBar()
    }

    if (couponSiteBar.scroll) {
      addEventListener("scroll", listenerEventScroll, false)
    }

  }

  getButtonDownload(couponSiteBar: CouponSiteBar) {
    return `
        <a type="button" class="button" target="_blank" href="${couponSiteBar.url}">
            <div class="copy-container">
                <svg id="copy-icon" width="17" height="17" viewBox="0 0 17 17" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M8.79213 12.8061L3.79213 7.80609L5.19213 6.35609L7.79213 8.95609V0.806091H9.79213V8.95609L12.3921 6.35609L13.7921 7.80609L8.79213 12.8061ZM2.79213 16.8061C2.24213 16.8061 1.7713 16.6103 1.37963 16.2186C0.987963 15.8269 0.79213 15.3561 0.79213 14.8061V11.8061H2.79213V14.8061H14.7921V11.8061H16.7921V14.8061C16.7921 15.3561 16.5963 15.8269 16.2046 16.2186C15.813 16.6103 15.3421 16.8061 14.7921 16.8061H2.79213Z"
                    fill="${couponSiteBar.backgroundColor || "#FF7300"}" />
                </svg>
            </div>
            <div id="coupon-value">${couponSiteBar.buttonText}</div>
        </a>`
  }

  getButtonOffer(couponSiteBar: CouponSiteBar) {
    return `
        <a type="button" class="button anc-button" target="_blank" href="${couponSiteBar.url}">
            ${couponSiteBar.buttonText}
        </a>`
  }

  getButtonCoupon(couponSiteBar: CouponSiteBar) {
    return `<button type="button" class="button" id="copy-icon">
        <div class="copy-container">
            <svg width="18" height="21" viewBox="0 0 18 21" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.79213 16.8062C6.24213 16.8062 5.7713 16.6103 5.37963 16.2187C4.98796 15.827 4.79213 15.3562 4.79213 14.8062V2.80615C4.79213 2.25615 4.98796 1.78532 5.37963 1.39365C5.7713 1.00199 6.24213 0.806152 6.79213 0.806152H15.7921C16.3421 0.806152 16.813 1.00199 17.2046 1.39365C17.5963 1.78532 17.7921 2.25615 17.7921 2.80615V14.8062C17.7921 15.3562 17.5963 15.827 17.2046 16.2187C16.813 16.6103 16.3421 16.8062 15.7921 16.8062H6.79213ZM6.79213 14.8062H15.7921V2.80615H6.79213V14.8062ZM2.79213 20.8062C2.24213 20.8062 1.7713 20.6103 1.37963 20.2187C0.987963 19.827 0.79213 19.3562 0.79213 18.8062V4.80615H2.79213V18.8062H13.7921V20.8062H2.79213Z"
                fill="${couponSiteBar.backgroundColor || "#FF7300"}" />
            </svg>
            <img class="" id="copied-icon" style="display: none;"
            src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%20fill='%234CAF50'%20%3e%3c!--!%20Font%20Awesome%20Free%206.2.1%20by%20@fontawesome%20-%20https://fontawesome.com%20License%20-%20https://fontawesome.com/license/free%20(Icons:%20CC%20BY%204.0,%20Fonts:%20SIL%20OFL%201.1,%20Code:%20MIT%20License)%20Copyright%202022%20Fonticons,%20Inc.%20--%3e%3cpath%20d='M470.6%20105.4c12.5%2012.5%2012.5%2032.8%200%2045.3l-256%20256c-12.5%2012.5-32.8%2012.5-45.3%200l-128-128c-12.5-12.5-12.5-32.8%200-45.3s32.8-12.5%2045.3%200L192%20338.7%20425.4%20105.4c12.5-12.5%2032.8-12.5%2045.3%200z'/%3e%3c/svg%3e"
            width="18" height="21" loading="lazy" alt="Ícone de copiar">
        </div>
        <div id="coupon-value">${couponSiteBar.coupon}</div>
        </button>`
  }

  getStyle(backgroundColor: string, buttonColor: string, textColor: string) {
    return `
        <style>
        .position-TOP {
            position: fixed;
            top: 20px;
          }

          .position-BOTTOM {
            position: fixed;
            bottom: 60px;
          }

          .site-bar {
            width: 90%;
            height: 80px;
            margin: auto;
            z-index: 999;
            left: 50%;
            transform: translate(-50%);
          }

          .container-sb {
            background: ${backgroundColor};
            color: ${textColor};
            display: flex;
            justify-content: space-between;
            box-shadow: 0px 0px 15px 0px #00000026;
            border-radius: 4px;
            padding: 16px;
            margin: auto;
            font-family: Asap;
            position: relative;
          }

          #close-bar {
            position: absolute;
            top: -8px;
            right: -8px;
            border-radius: 50%;
            background-color: ${backgroundColor};
            font-size: 20px;
            width: 24px;
            height: 24px;
            font-weight: 700;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;


            @media (min-width: 768px) {
              position: relative;
              margin: auto 16px;
              top: inherit;
              right: inherit;
              font-size: 36px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              cursor: pointer;
              width: auto;
              height: auto;
              text-align: center;
            }
          }

          .close-icon {
            margin: auto;
          }

          .button-container-sb {
            display: flex;
            justify-content: space-between;
          }

          .button {
            margin: auto;
            background: ${buttonColor};
            color: ${textColor};
            border-radius: 4px;
            border-style: none;
            margin-right: 4px;
            max-width: 136px;
            height: 48px;
            display: flex;
            cursor: pointer;
            width: max-content;
          }

          .anc-button {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 12px;
            min-width: max-content;
          }

          .main-container-sb-wrap {
            display: flex;
            flex-direction: column;
            max-height: 100px;
            text-align: left;
            width: 100%;
            max-width: 100%;
            padding: 4px 0;

            @media (min-width: 1000px) {
              flex-direction: row;
            }
          }

          .main-text-sb {
            margin: auto 8px;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            text-align: left;
            padding: 0;
            word-break: break-word;

            @media (min-width: 768px) {
              font-size: 20px;
              line-height: 26px;
            }

            @media (min-width: 1000px) {
              top: 50%;
              position: relative;
              transform: translateY(-50%);
              display: contents;
            }

          }

          .sub-text-sb {
            margin: auto 8px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            word-break: break-word;

            @media (min-width: 1000px) {
              font-size: 14px;
              font-weight: 700;
              line-height: 18px;
            }

            @media (min-width: 768px) {
              font-size: 14px;
              line-height: 16px;
            }
          }

          .copy-container {
            margin: auto;
            margin-right: 8px;
            margin-left: 8px;
          }

          #copied-icon {
            max-width: fit-content;
          }

          #coupon-value {
            margin: auto;
            margin-right: 8px;
            min-width: max-content;
          }

          .extra-text-sb {
            display: none;
            word-break: break-word;

            @media (min-width: 1000px) {
              display: inherit !important;
              padding-left: 16px;
              margin: auto;
              max-height: 48px;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
            }

          }

          .hide-close-sb {
              display: none;
            }
            </style>
        `
  }
}

new CouponSiteBarController();
