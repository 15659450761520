module.exports = {
    current: function(callback) {
        var ajax = new XMLHttpRequest();

        ajax.open('GET', '/t/user/me', true);
        ajax.send();

        ajax.onreadystatechange = function() {
            if (ajax.readyState == 4) {
                if (200 <= ajax.status && ajax.status < 300 && !!ajax.responseText)
                    callback(JSON.parse(ajax.responseText));
                else
                    callback(null);
            }
        }
    },
};
