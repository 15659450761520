/**
 * A manual made common ts to be executed in all pages.
 *
 * Currently responsible for:
 *   search-form
 */

// search-form
import '../../../../src/_v2/application/pages-base';

// login button header
import '../commons/components/gv-login-header/gv-login-header';

// TODO: refactor search form

import './ab-test';

import './popup';

import './coupon-site-bar';
