module.exports = {    
    KEYS: {
        38: "UP",
        40: "DOWN"
    },
    UNCAUGHT_ERROR: "Erro no Autocomplete",
    NO_INPUT_ERROR: "Please inform autocomplete input element.",
    NO_DATA_ERROR: "Please inform the autocomplete data object.",
    NO_RESULT_FOUND: "Nenhum resultado encontrado",
    QUOTA_EXCEEDED: "Quota Exceeded ERROR",
    NO_AUTOCOMPLETE_CLASS_ERROR: "Please inform the \'autocompleteClass\' param", 
    NO_AUTOCOMPLETE_CLASS_ERROR: "Please inform the \'autocompleteActiveClass\' param", 
    NO_ITEM_CLASS_ERROR: "Please inform the \'itemClass\' param",
    NO_ITEM_FOCUS_CLASS_ERROR: "Please inform the \'itemFocusClass\' param",
    NO_ITEM_HISTORY_CLASS_ERROR: "Please inform the \'itemHistoryClass\' param"
};