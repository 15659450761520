export enum RestEndPoint {
    // payment
    CUPOM = '/t/api/pagamento/cupom',
    PAYMENT_METHOD = '/t/api/pagamento/payment-methods',
    PAYMENT = '/t/pagamento/sec/pay/v2',
    LOGGED_USER = '/t/user/me',
    LOGOUT = '/t/user/logout',
    CHECK_OPERATION_STATUS = '/t/api/pagamento/checkOperationStatus',
    INDEX = '/t/api/pagamento/operation',
    CARD_BIN = '/t/api/pagamento/card-bin',
    ONE_CLICK = '/t/api/pagamento/one-click',

    // account
    AUTHENTICATION = '/t/api/account/authenticate',
    USER_INFO = '/t/api/account/users/detail',
    USER_TRAVELS = '/t/api/account/users/travels',
    CREATE_USER = '/t/api/account/users',
    RESET_PASSWORD = '/t/api/account/password/reset',
    UPDATE_USER = '/t/api/account/users',
    CHANGE_PASSWORD = '/t/api/account/password',
    DELETE_ONE_CLICK = '/t/api/account/payment-methods/one-click',
    FINANCIAL_STATEMENTS = '/t/api/account/financial-statements',
    ACCOUNT_VALUE = '/t/api/account/value'
}

