import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { NetworkError, RequestError } from './error';

class Client {
    private axios: AxiosInstance;


    constructor() {
        this.axios = axios.create();

        const cookiesBlocked = localStorage.getItem('isCookieBlocked')
        if(cookiesBlocked === 'true') {
            this.axios = axios.create({
                headers: {
                    'blockCookies': true
                }
            })
        }

        this.axios.interceptors.response.use(this.onRequestSuccess, this.onRequestError);
    }



    post = async (url: string, body: any, headers?: any): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await this.axios.post(url, body, this.getRequestParams({ headers }));
            return result.data;
        } catch (error) {
            throw error;
        }
    };

    put = async (url: string, body: any): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await this.axios.put(url, body, this.requestConfig());
            return result.data;
        } catch (error) {
            throw error;
        }
    };

    patch = async (url: string, body: any): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await this.axios.patch(url, body, this.requestConfig());
            return result.data;
        } catch (error) {
            throw error;
        }
    };

    get = async ({ url, params, headers }: { url: string; params?: any; headers?: any }): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await this.axios.get(url, this.getRequestParams({ params, headers }));
            return result.data;
        } catch (error) {
            throw error;
        }
    };

    delete = async (url: string, params?: any): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await this.axios.delete(url, this.requestConfig());
            return result.data;
        } catch (error) {
            throw error;
        }
    };

    private onRequestSuccess = (response: any): Promise<any> => {
        return response;
    };

    private onRequestError = (error) => {
        return Promise.reject(this.toError(error));
    };

    private toError(error: any): NetworkError | RequestError {
        if (error.message === 'Network Error') {
            return new NetworkError(error, error.config.url);
        }

        return new RequestError(
            error,
            error.config.url,
            error.statusCode || error.response.status,
            error.response.data
        );
    }

    private getRequestParams({ params, headers }: { params?: any; headers?: any }): AxiosRequestConfig {
        const newConfig: AxiosRequestConfig = this.requestConfig();
        newConfig.params = params;
        newConfig.headers = headers;
        return newConfig;
    }

    private requestConfig(): AxiosRequestConfig{
        return {timeout:150000}
    }
}

export const HttpClient: Client = new Client();
