module.exports = {
  data: {
    tabClass: 'shell-tab',
    // tabActiveClass: 'is-active ',
    tabItemClass: '.tab',
    tabItemActiveClass: 'tab-selected',

    //mantêm
    triggerBackClass: '.js-trigger-tab-back',
    formSearchGoClass: '.shell-search-wrapper-go',
    formSearchBackClass: '.shell-search-wrapper-back',
    formSearchInputBackClass: '.js-input-data-volta',
    formSearchBackValClass: '.js-input-data-volta-val',
  },

  // Listen Events...
  init: function() {
    this.cacheDom();

    var self = this;
    if (!this.$tab) {
      return;
    }

    [].forEach.call(
      this.$tab.querySelectorAll(self.data.tabItemClass),
      function(item) {
        item.addEventListener('click', function(e) {
          e.stopPropagation();
          self.toggleTabItem(item);
        });
      }
    );

    this.$formSearchBackToggle.addEventListener(
      'autofillBackHistory',
      function() {
        self.autofillBackSearchInput(this.selectedIndex);
      }
    );
  },

  // Initial Autofill based on previous searches
  autofillBackSearchInput: function(a) {
    this.toggleTabItem(
      this.$tab.querySelectorAll(this.data.tabItemClass)[1]
    );
  },

  // Toggle tab item visibility
  toggleTabItem: function(currentItem) {
    var self = this;
    var activeItems = currentItem.parentElement.querySelectorAll(
      '.' + this.data.tabItemActiveClass
    );

    for (var i = 0; i < activeItems.length; i++) {
      self.uncheckTabItem(activeItems[i]);
    }

    if (
      !currentItem.className.indexOf(this.data.tabItemActiveClass) != -1
    ) {
      this.checkTabItem(currentItem);
    }
    document.querySelector('#form-search').dispatchEvent(window.checkForm);
  },

  // Check tab item
  checkTabItem: function(currentItem) {
    currentItem.classList.add(this.data.tabItemActiveClass);

    this.toggleBackSearchInput(parseInt(currentItem.dataset.option));
  },

  // Uncheck tab item
  uncheckTabItem: function(el) {
    el.classList.remove(this.data.tabItemActiveClass);
  },

  // Toggle back search input visibility
  toggleBackSearchInput: function(selectedIndex) {
    var onlyGo = selectedIndex == 0;

    this.$formSearchBack.classList[onlyGo ? 'remove' : 'add']('is-visible');
    this.$formSearchValBack.disabled = onlyGo;
    if (onlyGo) {
      this.$formSearchInputBackClass.removeAttribute('required');
      this.$formSearchInputBackClass.setAttribute('disabled', true);
    } else {
      this.$formSearchInputBackClass.setAttribute('required', 'required');
      this.$formSearchInputBackClass.removeAttribute('disabled');
    }
  },

  cacheDom: function() {
    this.$tab = document.querySelector('.' + this.data.tabClass);
    this.$formSearchBackToggle = document.querySelector(
      this.data.triggerBackClass
    );
    this.$formSearchGo = document.querySelector(
      this.data.formSearchGoClass
    );
    this.$formSearchBack = document.querySelector(
      this.data.formSearchBackClass
    );
    this.$formSearchInputBackClass = document.querySelector(
      this.data.formSearchInputBackClass
    );
    this.$formSearchValBack = document.querySelector(
      this.data.formSearchBackValClass
    );
  },
};
