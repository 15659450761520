import * as Cookies from 'js-cookie';

const cancelLinkRemoved = Cookies.get('cancelLinkRemoved');

if(cancelLinkRemoved === 'true') {
    const menuLink = document.querySelector('#cancelMenuLink');
    if(menuLink) {
        menuLink.remove();
    }
}

const newHeader = Cookies.get('newHeader');

if(newHeader === 'true') {
    const menuLink = document.querySelector('.shell-sidebar-trigger');
    const links = document.querySelector('.gv-taskbar-links');
    if(menuLink) {
        menuLink.classList.add('hide-sidebar');
        links.classList.add('show-links');
    }
}
