/**
 * Loading component that has a motion gif.
 */
export class LoadingComponent {
    private _id: string;
    private _version: string;
    private _alt: string;
    private _title: string;

    constructor(id: string, alt: string, title: string = 'Carregando') {
        this._id = id;
        this._version = document.body.dataset.staticAws;
        this._alt = alt;
        this._title = title;
    }

    appear(parent: Element, disableSafari: boolean = false): void {
        const element: Element = document.getElementById(this._id);
        if (!!element) return;

        const newElement: Element = document.createElement('div');

        newElement.innerHTML = `<div class="gp-loader" id="${this._id}">
                                    <div class="loader-content${disableSafari && this.isSafari() ? " disable-safari" : ""}">
                                        <img src="${this._version}/_v2/static/img/right-arrow.svg"
                                            class="upper-arrow">
                                        <img src="${this._version}/_v2/static/img/right-arrow.svg"
                                            class="left-arrow">
                                    </div>
                                </div>`;

        parent.appendChild(newElement);
    }

    disappear(): void {
        const element: Element = document.getElementById(this._id);

        if (element) element.parentNode.removeChild(element);
    }

    private isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
}