var GVAutocomplete = require('./components/autocomplete/shell-autocomplete');
var GVFormValidate = require('./components/form-validation/gv-form-validation-new');
var UserService = require('./components/user/user-session');
var GVCommon = require('./components/common/gv-common');

/**
 * @description Scripts essenciais para o carregamento Inicial da página
 */

var AppShell = {
    data: {
        userDevice: function () {
            var width = window.innerWidth;
            //fazendo um cache do valor do width para evitar o reflow
            window.gvWidth = width;
            return width < 767
                ? 'phone'
                : width >= 768 && width < 1000
                    ? 'tablet'
                    : 'desktop';
        },
    },

    components: {
        search: !!document.querySelector('body').getAttribute('data-search'),
        hasSearch: !!document.querySelector('.shell-search-from'),
        banner: !!document.querySelector('body').getAttribute('data-banner'),
    },

    init: function () {
        this.cacheDom();
        this.setCustomEvents();
        this.loadCurrentUser();

        if (this.components.search || this.components.hasSearch) {
            GVAutocomplete.init();
            GVFormValidate.init();
        }

        var self = this;
        var device = this.data.userDevice();

        this.onResizeEnd(function () {
            if (device != self.data.userDevice()) {
                device = self.data.userDevice();
                self.$body.dispatchEvent(window.deviceChanged);
            }
        }, 100);

        // this.$body.addEventListener("deviceChanged", function(e) {
        // });

        window.addEventListener('pageshow', function (e) {
            if (e.persisted) {
                setTimeout(function () {
                    window.location.reload();
                }, 10);
            }
        });

        if (document.querySelector('#shell-sidebar-trigger')) {

            if (GVCommon.data.userDevice() == 'desktop') {
                document.addEventListener('scroll', function (e) {
                    if (document.querySelector('#shell-sidebar-trigger').checked === true)
                        document.querySelector('#shell-sidebar-trigger').checked = false;
                });
            }

            window.addEventListener('beforeunload', function (event) {
                document.querySelector('#shell-sidebar-trigger').checked = false;
            });
        }
    },

    pushDataLayer: function (user) {
        window.dataLayer = window.dataLayer || [];

        dataLayer.push({
            'email': user.email
        });
    },

    setCustomEvents: function () {
        if (!('createEvent' in document)) return;
        var eventsObj = ['deviceChanged', 'checkForm', 'scrollOutAboveTheFold', 'autofillBackHistory'];

        [].forEach.call(eventsObj, function (eventName) {
            window[eventName] = document.createEvent('HTMLEvents');
            window[eventName].initEvent(eventName, false, true);
        });
    },

    onResizeEnd: function (callback, waitTime) {
        var doIt;

        window.onresize = function () {
            clearTimeout(doIt);
            doIt = setTimeout(function () {
                if (callback) callback();
            }, waitTime);
        };
    },

    cacheDom: function () {
        this.$body = document.querySelector('body');
        this.$userDisplay = document.querySelector('.js-user-name');
        this.$sideBarHeader = document.querySelector('.shell-sidebar-header');

        this.$sideBarLogo = document.querySelector('.menu_user');
        this.$sideBarMargin = document.querySelector('.shell-sidebar-trigger');
        this.$sideBarListLogon = document.querySelector('.list_user');
        this.$sideBarExit = document.querySelector('.list_user_exit');
        this.$sideBarLogin = document.querySelector('.shell-sidebar-login');

        this.$listItemSidebarLogin = document.querySelector('.js-account-amplitude');
    },

    createAvatarImg: function(userPicture) {
        if (!!userPicture) {
            let img = document.createElement('img');
            img.classList.add('ssh-user-image');
            img.src = userPicture;
            return img;
        } else {
            let i = document.createElement('i');
            i.classList.add('material-icons');
            i.classList.add('loggon');
            i.textContent = 'person';
            return i;
        }
    },

    createUserEmailDiv: function(name, email) {
        let sml = document.createElement('small');
        sml.textContent = email;

        let div = document.createElement('div');
        div.classList.add('loggon_text');
        div.textContent = 'Olá, ' + name;
        div.appendChild(sml);

        return div;
    },

    loadCurrentUser: function () {
        var self = this;
        document.addEventListener('DOMContentLoaded', function (event) {
            UserService.current(function(user) {
                if (!self.$userDisplay || !self.$sideBarHeader) return;

                if (user) {
                    const userPicture = localStorage.getItem('user-picture');
                    self.$listItemSidebarLogin.classList.add('p-display-none');
                    self.$userDisplay.classList.remove('p-display-none');

                    self.$userDisplay.querySelector('.ssh-user-name').appendChild(self.createAvatarImg(userPicture));
                    self.$userDisplay.querySelector('.ssh-user-name').appendChild(self.createUserEmailDiv(user.firstName, user.email));

                    self.$sideBarHeader.classList.add('ssh-logged');
                    if(self.$sideBarMargin) {
                        self.$sideBarMargin.classList.add('log_user');
                    }
                    self.$sideBarLogin.classList.add('login_ok');

                    self.pushDataLayer(user);

                } else {
                    self.$listItemSidebarLogin.classList.remove('p-display-none');
                    self.$userDisplay.classList.add('p-display-none');
                    self.$userDisplay.querySelector('.ssh-user-name').innerHTML = '';
                    self.$sideBarHeader.classList.remove('ssh-logged');

                    self.$sideBarListLogon.classList.add('user_logoff');
                    self.$sideBarExit.classList.add('logoff');
                }
            });
        });
    },
};

AppShell.init();
