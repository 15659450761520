var AmplitudeService = require('../amplitude/amplitude-config');

const EVENTS = [
    {selector: ".js-only-go-amplitude", name: "search_form__select_go_trip_btn", eventType: "click"},
    {selector: ".js-radio-only-go-amplitude", name: "search_form__select_go_trip_btn"},
    {selector: ".js-go-back-amplitude", name: "search_form__select_go_and_back_trip_btn", eventType: "click"},
    {selector: ".js-radio-go-back-amplitude", name: "search_form__select_go_and_back_trip_btn"},
    {selector: ".js-origin-amplitude", name: "search_form__select_origin_input"},
    {selector: ".js-destination-amplitude", name: "search_form__select_destination_input"},
    {selector: ".js-dateGo-amplitude", name: "search_form__select_go_date"},
    {selector: ".js-dateBack-amplitude", name: "search_form__select_back_date"},
    {selector: ".js-inverte-route-amplitude", name: "search_form__select_invert_route_btn"},
    {selector: ".js-search-button-click-amplitude", name: "search_form__submit_btn"}
];

module.exports = {
    init: function() {
        EVENTS.forEach(function(event) {
            let el = document.querySelector(event.selector);

            if (!!el) {
                let eventType = event.eventType || "focus";
                el.addEventListener(eventType, function() {
                    AmplitudeService.sendAmplitudeEvent(event.name, {description: "Search form event"}, {search: 'search'});
                });
            }
        });
    },
}