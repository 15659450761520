import { UserCreate } from 'account/interfaces/user-create.interface';
import { UserInfo } from 'account/interfaces/user-info.interface';
import { HttpClient } from 'commons/ts/http.client';
import { RestEndPoint } from 'commons/ts/utils/rest.endpoints';

class Service {
    // workaround until we have the new home
    async getUser(): Promise<UserInfo> {
        try {
            const userInfo: any = await HttpClient.get({ url: RestEndPoint.LOGGED_USER });

            return userInfo;
        } catch (error) {
            return null;
        }
    }

    async logout(): Promise<void> {
        await HttpClient.post(RestEndPoint.LOGOUT, null);
    }

    async getUserInfo(): Promise<UserInfo> {
        try {
            const userInfo: UserInfo = await HttpClient.get({ url: RestEndPoint.USER_INFO });

            if (!userInfo.email) {
                throw 'Não foi possível buscar as informações do usuário';
            }

            return userInfo;
        } catch (error) {
            throw error;
        }
    }

    async createUser(user: UserCreate): Promise<UserInfo> {
        try {
            const userInfo: UserInfo = await HttpClient.post(RestEndPoint.CREATE_USER, user, {
                'X-Captcha' : user.token
            });

            if (!userInfo.email) {
                throw 'Não foi possível buscar as informações do usuário';
            }

            return userInfo;
        } catch (error) {
            if (error.statusCode === 403 || error.statusCode === 409) {
                throw 'Usuário já cadastrado';
            }
            throw error;
        }
    }

    async updateUser(user: UserInfo): Promise<UserInfo> {
        try {
            const userInfo: UserInfo = await HttpClient.put(RestEndPoint.UPDATE_USER, user);

            return userInfo;
        }
        catch (error) {
            throw error.errorResponse;
        }
    }

    async redefinePassword(email: string): Promise<void> {
        try {
            await HttpClient.get({ url: RestEndPoint.RESET_PASSWORD, params: { email } });
        } catch (error) {
            throw error.errorResponse;
        }
    }

    async changePassword(password): Promise<void> {
        try {
            await HttpClient.put(RestEndPoint.CHANGE_PASSWORD, password);
        }
        catch (error) {
            throw error.errorResponse;
        }
    }

    async deleteOneClick(id: number): Promise<void> {
        try {
            const url = `${RestEndPoint.DELETE_ONE_CLICK}/${id}`;
            await HttpClient.delete(url);
        } catch (error) {
            throw error.errorResponse;
        }
    }

}

export const AccountService: Service = new Service();
export default AccountService;
