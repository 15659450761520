export class BaseError implements Error {

    constructor(name: string, message: string) {
        this.name = name;
        this.message = message;
    }

    name: string;
    message: string;
}

export class RedirectError extends BaseError {
    constructor(message: string, url: string) {
        super('Redirect Errror', message);
        this.url = url;
    }
    url: string;
}

export class NetworkError extends BaseError {
    constructor(message: string, url: string) {
        super('Network Errror', message);
        this.url = url;
    }
    url: string;
}

export class RequestError extends BaseError {
    constructor(message: string, url: string, statusCode: number, response: ErrorResponse) {
        super('Request Error', message);
        this.url = url;
        this.statusCode = statusCode;
        this.errorResponse = response;
    }
    url: string;
    statusCode: number;
    errorResponse: ErrorResponse;
}

export class UIValidationError extends BaseError {
    constructor(message: string, object: any, errors: Array<string>) {
        super('Ui Validation Error', message);
        this.object = object;
        this.errors = errors;
    }
    object: any;
    errors: Array<string>;
}

export class ErrorResponse extends BaseError {
    constructor(message: string, url: string, statusCode: number) {
        super('Request Error', message);
        this.url = url;
        this.statusCode = statusCode;
    }

    url: string;
    statusCode: number;
    code: string;
    text: string;
    timeStamp: string;
    properties: Array<ErrorResponseType>;
    children: Array<ErrorResponse>;
}

export class ErrorResponseType {
    name: string;
    value: any;
    type: string;
}