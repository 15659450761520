const datepicker = require('./jsdatepicker');

module.exports = {
  init: function (formObj) {
    const MOBILE_MIN_WIDTH = 425; // this should be placed in a specific file on future refactor

    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const days = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

    // use 2 hours delay for better UX (out of date browser and other issues...)
    const now = new Date(Date.now() - 2*60*60*1000);

    const goTextfield = document.querySelector(formObj.ida);
    const backTextfield = document.querySelector(formObj.volta);

    // Hidden input field which contains the value sent to the backend
    const goInput = document.querySelector(formObj.idaVal);
    const backInput = document.querySelector(formObj.voltaVal);

    // Background pannel for mobile. Each datepicker must have its own pannel
    const bkgPannelGo = document.querySelector(formObj.idaPannel);
    const bkgPannelBack = document.querySelector(formObj.voltaPannel);

    const pannelOpenClass = 'gv-datepicker-bkg-pannel-open';

    eventsSearchDate();

    /**
     * Sets the $input.value parsing the $date value
     * Format example: seg, 11/nov
     *
     * @param {HTMLInputElement} input
     * @param {Date} date
     */
    function formatInput(input, date) {
      const day = days[date.getDay()].toLowerCase();
      const monthDay = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
      const month = months[date.getMonth()].slice(0,3).toLowerCase();

      input.value = `${day}, ${monthDay}/${month}`;
    }

    /**
     * Format expected date in api
     *
     * @param {Date} date
     * @return {String}
     */
    function formatDMY(date) {
      var month = date.getMonth();
      var year = date.getUTCFullYear();
      var day = date.getUTCDate();

      month = ++month > 9 ? "" + month : "0" + month;

      return `${day}/${month}/${year}`;
    }

    /**
     * On datepicker show callback event. If on mobile, open the background pannel
     * and disable scroll.
     *
     * @param {String} data
     * @return {String}
     */
    function formatData (data) {
      data = data.replace(/\D/g, "");
      data = data.replace(/(\d{2})(\d)/, "$1/$2");
      data = data.replace(/(\d{2})(\d)/, "$1/$2");

      return data;
    };

    /**
     * On datepicker show callback event. If on mobile, open the background pannel
     * and disable scroll.
     *
     * @param {HTMLElement} pannel
     */
    function onShow(pannel) {
      if (!pannel)
        return;

      if (!pannel.classList.contains(pannelOpenClass)) {
        if (window.gvWidth <= MOBILE_MIN_WIDTH)
          document.body.style.overflow = "hidden"; // disable scroll
        pannel.classList.add(pannelOpenClass);
      }
    }

    /**
     * On datepicker hide callback event. If on mobile, close the background pannel
     * and enable scroll. Basically, undo the onShow event.
     *
     * @param {HTMLElement} pannel
     */
    function onHide(pannel) {
      if (!pannel)
        return;

      if (pannel.classList.contains(pannelOpenClass)) {
        if (window.gvWidth <= MOBILE_MIN_WIDTH)
          document.body.style.overflow = ""; //enable scroll
        pannel.classList.remove(pannelOpenClass);
      }
    }

    /**
     * Parses the value of $dateInputField to Date.
     * Expects $dateInputField.value to be in dd/mm/yyyy format.
     * Returns null if in another circumstance.
     *
     * @param {HTMLInputElement} dateInputField
     * @return {Date}
     */
    function getDate(dateInputField) {
      if (!dateInputField || !dateInputField.value)
        return null;

      const formatRgx = /^(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/[1-2][0-9][0-9][0-9]$/g
      const valueStr = dateInputField.value;

      const match = valueStr.match(formatRgx);
      if (!match || match[0] != valueStr) {
        console.error('[DATEPICKER] - dateInputField wrong format');
        return null;
      }

      const date = valueStr.split('/');
      const day = parseInt(date[0]);
      const month = parseInt(date[1])-1;
      const year = parseInt(date[2]);

      return new Date(year,month,day);
    }

    /**
     * Change format week, day/month to d/m/y
     *
     * @param {HTMLInputElement} textField
     * @param {HTMLInputElement} input
     */
    function searchDateFocus(textField, input) {
      if (input.value != '' && getDate(input)) {
        textField.value = input.value;
      } else {
        input.value = '';
      }
    }

    /**
     * Change format d/m/y to week, day/month
     *
     * @param {HTMLInputElement} textField
     * @param {HTMLInputElement} input
     */
    function searchDateFocusOut(textField, input) {
      if (getDate(textField)) {
        input.value = textField.value;

        formatInput(textField, getDate(textField));
        textField.dispatchEvent(new CustomEvent('VALIDATE_SEARCH_FORM'));
      }
    }

    /**
     * Apply mask to input
     *
     * @param {HTMLInputElement} textField
     */
    function searchDateOnChange(textField) {
      textField.value = formatData(textField.value);
    }

    function eventsSearchDate() {
      goTextfield.addEventListener('keyup', () => searchDateOnChange(goTextfield));
      goTextfield.addEventListener('focus', () => searchDateFocus(goTextfield, goInput));
      goTextfield.addEventListener('focusout', () => searchDateFocusOut(goTextfield, goInput));

      if(backInput) {
          backTextfield.addEventListener('keyup', () => searchDateOnChange(backTextfield));
          backTextfield.addEventListener('focus', () => searchDateFocus(backTextfield, backInput));
          backTextfield.addEventListener('focusout', () => searchDateFocusOut(backTextfield, backInput));
      }
    }

    const backDatePicker = datepicker(formObj.volta, {
      minDate: !!goInput.value ? getDate(goInput) : now,
      dateSelected: getDate(backInput),
      onShow: ()=>onShow(bkgPannelBack),
      onHide: ()=>onHide(bkgPannelBack),
      customMonths: months,
      customDays: days,
      formatter: formatInput,
      disableYearOverlay: true,
      onSelect: function (instance, date) {
        if (!date)
          backInput.value = "";

        backDatePicker.hide(); // on mobile, under some circunstances, it doesn't close by himself
        backTextfield.dispatchEvent(new CustomEvent('VALIDATE_SEARCH_FORM'));
        backInput.value = formatDMY(date);
      }
    });

    const goDatePicker = datepicker(formObj.ida, {
      minDate: now,
      dateSelected: getDate(goInput),
      onShow: ()=>onShow(bkgPannelGo),
      onHide: ()=>onHide(bkgPannelGo),
      customMonths: months,
      customDays: days,
      formatter: formatInput,
      disableYearOverlay: true,
      onSelect: function (instance, date) {
        const backMinDate = date || now;

        if (!!backDatePicker.dateSelected) {
          if (backDatePicker.dateSelected.getTime() < backMinDate.getTime()) {
            backDatePicker.setDate(backMinDate);
          }
        }

        backDatePicker.setMin(backMinDate);
        goDatePicker.hide(); // on mobile, under some circunstances, it doesn't close by himself
        goTextfield.dispatchEvent(new CustomEvent('VALIDATE_SEARCH_FORM'));
        goInput.value = formatDMY(date);
      }
    });
  },
}
