var SearchHistory = require("./shell-autocomplete-search-history");
var MESSAGES = require("./shell-autocomplete-messages");
var common = require('../common/gv-common');

/**
 * @description Plugin de Autocomplete
 */

function Autocomplete(obj) {

    try {

        if (!obj.input) throw new Error(MESSAGES.NO_INPUT_ERROR);
        if (!obj.data) throw new Error(MESSAGES.NO_DATA_ERROR);
        if (!obj.autocompleteClass) throw new Error(MESSAGES.NO_AUTOCOMPLETE_CLASS_ERROR);
        if (!obj.autocompleteActiveClass) throw new Error(MESSAGES.NO_AUTOCOMPLETE_ACTIVE_CLASS_ERROR);
        if (!obj.itemClass) throw new Error(MESSAGES.NO_ITEM_CLASS_ERROR);
        if (!obj.itemFocusClass) throw new Error(MESSAGES.NO_ITEM_FOCUS_CLASS_ERROR);
        if (!obj.itemHistoryClass) throw new Error(MESSAGES.NO_ITEM_HISTORY_CLASS_ERROR);

        this.searchHistory = new SearchHistory();
        this.input = obj.input;
        this.appTriggerSidebar = obj.appTriggerSidebar;
        this.history = [];
        this.autocompleteClass = obj.autocompleteClass;
        this.autocompleteActiveClass = obj.autocompleteActiveClass;
        this.sidebarInactiveClass = obj.sidebarInactiveClass;
        this.itemClass = obj.itemClass;
        this.itemFocusClass = obj.itemFocusClass;
        this.itemHistoryClass = obj.itemHistoryClass;

        this.wrapperElement = obj.wrapper || "ul";
        this.wrapperItem = (this.wrapperElement === "ul") ? "li" : "div";
        this.fieldToCompare = obj.fieldToCompare || false;
        this.autocomplete_list = this.appendAutocompleteList();
        this.currentData;
        this.timeoutFn = null;
        this.timeoutMiliseconds = 300;

        var self = this;

        var customEvent = new CustomEvent('closeAutoCompletes', { 'detail': 'Fecha autoCompletes' });

        this.input.addEventListener('focusout', function (e) {

            var results = self.data.filter(function (item) {
                return e.target.value === item.label;
            });

            if (!results.length) {
                e.target.value = '';
            }
        });

        window.onpopstate = function (event) {

            document.activeElement.blur();
            self.input.classList.remove(self.autocompleteActiveClass);
            self.appTriggerSidebar.classList.remove(self.sidebarInactiveClass);

            try {
                window.dispatchEvent(customEvent);
            } catch (e) {
                throw new Error('Não rolou :( \nErro: ' + e);
            }
        };

        self.autocomplete_list.addEventListener("touchmove", function (e) {
            this.isTouchMoving = true;
        }, passiveSupported() ? {passive: true}: false);

        ["touchend", "mousedown"].forEach(function (listener) {
            self.autocomplete_list.addEventListener(listener, function (e) {
                if (e.type == "touchend") {
                    if (this.isTouchMoving) {
                        this.isTouchMoving = false;
                        return;
                    }
                }
                if (e.type == "mousedown" && e.offsetX > e.target.clientWidth) {
                    return;
                }
                if (!self.eventDelegating && e.target.nodeName == "LI" && (e.target.className.indexOf(self.itemFocusClass) == -1 || e.target.className.indexOf(self.itemHistoryClass) == -1)) {
                    self.onAutocompleteDelegated(e);
                } else if (e.target.nodeName == "UL") {
                    if (window.gvWidth >= 768) {
                        self.onAutocompleteSelect(e, null);
                    } else {
                        self.onbackAutocomplete(e);
                    }
                }
            }, passiveSupported() ? {passive: true}: false);
        });

        function passiveSupported() {
            try {
                document.addEventListener('test', null, {passive: true});
                document.removeEventListener('test', null, {passive: true});
                return true;
            }
            catch (e) {
                return false;
            }
        }

        self.searchHistory.load(function(history) {

            self.history = history;
            window.stations = self.searchHistory.weightStations(window.stations, history);

            self.data = obj.data;

            // Event Listeners
            self.input.onclick = self.clickHandler.bind(self);
            self.input.onpaste = self.clickHandler.bind(self);
            self.input.ondblclick = self.clickHandler.bind(self);
            self.input.onfocus = self.clickHandler.bind(self);
            self.input.onfocusin = self.clickHandler.bind(self);
            self.input.onkeyup = self.keyupHandler.bind(self);
            self.input.onblur = self.onblurHandler.bind(self);

            self.onFocusout = function (e) {

                var hasCached = self.currentData && !!self.currentData.label;

                // If input has not value, but there's a cached Label ...
                if (!self.input.value && hasCached) {
                    self.input.value = self.currentData.label;
                    self.input.previousElementSibling = self.currentData.value;
                }
                return obj.onFocusout(self.input) || null;
            };

            [].forEach.call(document.querySelectorAll(".js-back-autocomplete-mobile"), function (item) {
                item.addEventListener("click", function (e) {
                    self.onbackAutocomplete(e);
                });
            });
        });

    } catch(e) {
        console.error(MESSAGES.UNCAUGHT_ERROR + " - e="+JSON.stringify(e));
    }
}

Autocomplete.prototype.onbackAutocomplete = function(e) {

    if (this.currentData && this.currentData.label) {
        this.input.value = this.currentData.label;
        this.input.previousElementSibling.value = this.currentData.value;
    }
    this.closeAutocomplete(e);
};

Autocomplete.prototype.onAutocompleteDelegated = function(e) {

    this.eventDelegating = true;
    e.target.classList.add(this.itemFocusClass);
    this.onAutocompleteSelect(e, e.target);
};

Autocomplete.prototype.onAutocompleteSelect = function(e, delegated) {

    var itemChoosed = delegated || this.autocomplete_list.querySelector("." + this.itemFocusClass) || this.autocomplete_list.querySelectorAll("." + this.itemClass)[0];
    var isNotFound = !itemChoosed || itemChoosed.textContent == MESSAGES.NO_RESULT_FOUND;
    var isEnter = e.keyCode == 13;

    if (!isNotFound && delegated) {
        this.input.value = delegated.textContent;
        this.input.previousElementSibling.value = delegated.dataset.value;

        this.updateSearchHistory(itemChoosed.dataset.value);
        return;
    }

    if (isNotFound && !isEnter) {
        this.closeAutocomplete(e);
        return;
    }

    if (isNotFound && isEnter) {
        this.onbackAutocomplete(e);
        return;
    }

    // If input has value...
    if (this.input.value && !delegated) this.updateAutocompleteInputValue(itemChoosed);

    this.closeAutocomplete(e);
};

Autocomplete.prototype.updateAutocompleteInputValue = function (itemChoosed) {

    itemChoosed.classList.add(this.itemFocusClass);

    this.input.value = itemChoosed.textContent;
    this.input.previousElementSibling.value = itemChoosed.dataset.value;

    this.updateSearchHistory(itemChoosed.dataset.value);
};

Autocomplete.prototype.updateSearchHistory = function(itemChoosedValue) {

    try {
        this.history = this.searchHistory.add(this.history, itemChoosedValue);
        window.stations = this.searchHistory.weightStations(window.stations, this.history);
    }
    catch (error) {
        if (error && (error.number && error.number === -2147024882 /*IE8*/ ) || (error.code && error.code === 22 /*Browsers Modernos*/ ) || ((error.code && error.code === 1014) && (error.name && error.name === 'NS_ERROR_DOM_QUOTA_REACHED') /*FF*/ )) {
            console.log("Quota Exceeded");
        }
    }
};

Autocomplete.prototype.appendAutocompleteList = function () {

    var el = document.createElement(this.wrapperElement);
    el.setAttribute("class", this.autocompleteClass + "-list");
    this.input.parentNode.insertBefore(el, this.input.nextSibling);
    return el;
};

Autocomplete.prototype.onblurHandler = function (e) {

    if (window.gvWidth >= 768) {
        this.onblurHandlerDesktop(e);
    } else {
        this.onblurHandlerMobile(e);
    }
};

Autocomplete.prototype.onblurHandlerMobile = function(e) {

    this.onAutocompleteSelect(e);
};

Autocomplete.prototype.onblurHandlerDesktop = function(e) {

    var target = e.relatedTarget || document.activeElement;

    if (target && target.className.indexOf("sal-item") != -1) {

        var rule = e.target.dataset.rule;
        var nextTarget = (rule === "from") ? ".si-inverted-arrows-btn-trigger" : (rule === "to") ? ".js-input-data-ida" : false;
        if (nextTarget) document.querySelector(nextTarget).focus();

        this.onAutocompleteSelect(e);
    }
    else {
        this.closeAutocomplete(e);
    }
};

Autocomplete.prototype.keyupHandler = function (e) {

    switch (e.keyCode) {
    case 38: //UP
    case 40: // DOWN
        this.navigateHandler(e);
        break;

    case 27: // ESC
    case 13: // ENTER
        e.preventDefault();
        this.onAutocompleteSelect(e);
        break;

    case 17: // CONTROL
    case 16: // SHIFT
    case 18:
        // Do nothing ...
    break;

    default:
        this.fillAutocomplete(e);;
        break;
    }
};


Autocomplete.prototype.clickHandler = function (e) {

    this.currentData = {
        label: e.target.value,
        value: e.target.previousElementSibling.value
    };

    if (window.gvWidth < 768) {
        e.target.value = "";
    } else {
        e.target.select();
    }
    this.fillAutocomplete(e);
};

Autocomplete.prototype.closeAutocomplete = function (e) {

    this.eventDelegating = false;

    e.preventDefault();
    e.stopPropagation();

    this.autocomplete_list.parentElement.classList.remove(this.autocompleteActiveClass);

    setTimeout(function () {
        try {
            document.querySelector("#form-search").dispatchEvent(window.checkForm);
        }
        catch (e) {}
    }, 100);
    this.clearInputFocuses(this.autocomplete_list.querySelectorAll("." + this.itemClass));
    this.onFocusout();
    this.currentSelected = null;
};

Autocomplete.prototype.clearInputFocuses = function(items) {

    if (!items.length) return;
    var self = this;

    [].forEach.call(items, function (item) {
        item.classList.remove(self.itemFocusClass);
    });
    return true;
};

Autocomplete.prototype.navigateHandler = function (e) {

    var items = this.autocomplete_list.querySelectorAll("." + this.itemClass);
    if (items.length == 1 && items[0].innerHTML == MESSAGES.NO_RESULT_FOUND) return;

    var key = MESSAGES.KEYS[e.keyCode];
    var currentSelected = this.currentSelected;

    var hasCurrent = currentSelected || currentSelected == 0;

    this.clearInputFocuses(items);

    if (!hasCurrent && key === "UP") currentSelected = items.length;
    if (!hasCurrent && key === "DOWN") currentSelected = 0;

    if (hasCurrent && key === "UP") {
        if (currentSelected == 0) {
            currentSelected = (items.length - 1);
        }
        else {
            currentSelected--;
        }
    }

    if (hasCurrent && key === "DOWN") {
        if (currentSelected < (items.length - 1)) {
            currentSelected++;
        }
        else {
            currentSelected = 0;
        }
    }

    this.updateAutocompleteInputValue(items[currentSelected]);
    this.currentSelected = currentSelected;
};

Autocomplete.prototype.fillAutocomplete = function (e) {
    var self = this;

    clearTimeout(self.timeoutFn);

    var event = function(){
        common.addUrlStatus('autoComplete', { autoComplete: true });

        var onlyHistory;

        // No value and no search history...
        if (!e.target.value && !self.history.length) {
            self.autocomplete_list.innerHTML = '';
            return;
        }

        // No value, but with history search
        if (!e.target.value && this.history.length) {
            onlyHistory = true;
        }

        var autocompleteContent = "";
        var content = "";

        self.autocomplete(e.target.value).forEach(function (item, index) {

            if (onlyHistory) {
                if (item.historyOrder > -1) content += self.getItem(e,item, index);
            } else {
                content += self.getItem(e,item, index);
            }
            autocompleteContent = content;
        });

        self.autocomplete_list.innerHTML = autocompleteContent;
        self.autocomplete_list.parentElement.classList.add(self.autocompleteClass + "-active");
    }

    self.timeoutFn = setTimeout(event, self.timeoutMiliseconds);
};

Autocomplete.prototype.getItem = function (e,item, index) {

    var searchTerm = removeAccents(e.target.value.toLowerCase());
    var originalText = item.label;
    var itemText = removeAccents(originalText.toLowerCase());
    var beginBold = itemText.indexOf(searchTerm);
    var itemClass = this.itemClass + (item.historyOrder > -1 ? " " + this.itemHistoryClass : "");

    return  '<' + this.wrapperItem + ' tabindex="' + index + '" class="' + itemClass + '" data-value="'+item.value+'">' + ((beginBold === -1) ? originalText : this.appendBoldToElement(originalText, beginBold, searchTerm)) + '</' + this.wrapperItem + '>';
};

// Grifa trecho que foi digitado no item da lista
Autocomplete.prototype.appendBoldToElement = function (text, beginBold, searchTerm) {
    return text.substring(0, beginBold) + '<span class="ui-piece-location" style="font-weight: bolder;">' + text.substring(beginBold, beginBold + searchTerm.length) + '</span>' + text.substring(beginBold + searchTerm.length);
};

Autocomplete.prototype.autocomplete = function (term) {

    term = removeAccents(term.toLowerCase());

    var results = this.data.filter(function (item) {
        var itemLabel = item['label'].toLowerCase();

        // Verifica se existe trecho digitado na lista de cidades
        return removeAccents(itemLabel).indexOf(term) !== -1;
    });

    if (!results.length) return [{ value: "0", label: MESSAGES.NO_RESULT_FOUND, population: 0, order: 1, historyOrder: -1, starts: 0 }];

    results.forEach(function(item) {
       var itemLabel = removeAccents(item.label).toLowerCase();
       item.isPreffix = itemLabel.indexOf(term) === 0 ? 0 : -1;
       if(item.isPreffix == -1) {
           item.secondaryStart = itemLabel.indexOf(' ' + term);
       } else item.secondaryStart = -1;
    });
    results.sort(function (a, b) {
        return (b.historyOrder - a.historyOrder) || (b.isPreffix - a.isPreffix) ||
            ( b.secondaryStart - a.secondaryStart) ||
            (b.order - a.order) || (b.population - a.population);

    });
    return results.slice(0,25);
};

function removeAccents(str) {
    var removeAccents_map = {
        'Ã': 'A',
        'Â': 'A',
        'Á': 'A',
        'ã': 'a',
        'â': 'a',
        'á': 'a',
        'à': 'a',
        'É': 'E',
        'Ê': 'E',
        'È': 'E',
        'é': 'e',
        'ê': 'e',
        'è': 'e',
        'Í': 'I',
        'Î': 'I',
        'Ì': 'I',
        'î': 'I',
        'í': 'i',
        'ì': 'i',
        'Ô': 'O',
        'Õ': 'O',
        'Ó': 'O',
        'Ò': 'O',
        'ô': 'o',
        'õ': 'o',
        'ó': 'o',
        'ò': 'o',
        'Ú': 'U',
        'Ù': 'U',
        'ú': 'u',
        'ù': 'u',
        'ç': 'c'
    };
    return str.replace(/[^A-Za-z0-9\[\] ]/g, function (a) {
        return removeAccents_map[a] || a;
    });
}

module.exports = Autocomplete;
