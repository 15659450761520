import { HttpClient } from 'commons/ts/http.client';

// get asset url images const origin = window['assetSrc'] || window['staticAws'] || ''; + /image/view2/
interface Popup {
    id: number
    imgMobile?: DestinationBannerDTO
    imgTablet?: DestinationBannerDTO
    imgDesktop?: DestinationBannerDTO
    name: string
    url: string
    oncePerDay: boolean;
};

interface PopupStorage {
    id: number;
    closeDate: string;
}

interface DestinationBannerDTO {
    id: number
};

class PopupController {
    constructor() {
        this.init();
    }
    async init() {
        await this.initGeolocation();
        const popup: Popup | null = await this.getPopups();
        if (popup) {
            this.createPopup(popup)
        }
    }

    async getPopups() {
        const cityStorage = sessionStorage.getItem('city');
        const stateStorage = sessionStorage.getItem('state');
        const page = document.location.pathname;
        const search = document.location.search;
        const UTM_SOURCE = search.split("&").filter(s => s.includes("UTM_SOURCE"))[0];

        let device = 'mobile';
        let innerWidth = window.innerWidth;
        if (innerWidth > 768) {
            device = 'tablet';
            if (innerWidth >= 1000) {
                device = 'desktop';
            }
        }
        try {
            if (UTM_SOURCE) {
                const utm = UTM_SOURCE.split("=")[1];
                const utmRes = await HttpClient.get({
                    url: "/api/get-popup", params: {
                        value: utm,
                        method: 'ORIGIN',
                        page,
                        device
                    }
                });
                return utmRes;
            }
            if (cityStorage) {
                const cityRes = await HttpClient.get({
                    url: "/api/get-popup", params: {
                        value: cityStorage,
                        method: 'CITY',
                        complement: stateStorage,
                        page, device
                    }
                });
                if (cityRes) {
                    return cityRes;
                }

                const stateRes = await HttpClient.get({
                    url: "/api/get-popup", params: {
                        value: stateStorage,
                        method: 'STATE',
                        page, device
                    }
                });

                if (stateRes) {
                    return stateRes;
                }
            }
            const globalRes = await HttpClient.get({
                url: "/api/get-popup", params: {
                    method: 'NONE',
                    page, device
                }
            });

            if (!globalRes) {
                throw new Error('cannot find global ');
            }
            return globalRes;
        } catch (e) {
        }
        return null;
    }

    async initGeolocation() {
        const cityStorage = sessionStorage.getItem('city');
        const state = sessionStorage.getItem('state');
        if (cityStorage || state) {
            return;
        }
        try {
            const geoRequest = await HttpClient.get({ url: "/api/localization" });
            const { city, region } = geoRequest
            if (city) {
                sessionStorage.setItem('city', city);
            }
            if (region) {
                sessionStorage.setItem('state', region);
            }
        } catch (e) {
            console.log(e);
        }
    }

    getLocalStorageData(): PopupStorage[] | null {
        try {
            const json = JSON.parse(localStorage.getItem('last-popup'));
            if (!Array.isArray(json)) {
                localStorage.removeItem('last-popup');
                return [];
            }
            return json;
        } catch (error) {
            localStorage.removeItem('last-popup');
            return [];
        }
    }

    canBeOpen(popupIn: Popup): boolean {
        const storagePopup = this.getLocalStorageData();
        if (storagePopup == null || storagePopup?.length == 0) return true;
        const popupStorage = storagePopup.find(e => e.id == popupIn.id);
        if (!popupIn.oncePerDay) {
            return !popupStorage;
        }
        if (!popupStorage) {
            return true;
        }
        const now = new Date();
        return popupStorage.closeDate != now.toDateString();
    }

    createPopup(popup: Popup | null) {
        if (!popup) {
            return;
        }

        if (!this.canBeOpen(popup)) {
            return;
        }

        const link = document.createElement('a');
        link.classList.add('popup');
        link.href = popup.url;
        let img: string;
        if (popup.imgDesktop && popup.imgMobile && popup.imgTablet) {
            img = `<picture>
               <source media="(min-width:1000px)" srcset="/image/view2/${popup.imgDesktop.id}">
               <source media="(min-width:768px)" srcset="/image/view2/${popup.imgTablet.id}">
               <img src="/image/view2/${popup.imgMobile.id}" style="width: 100%; height: 100%;">
           </picture>`;
        } else if (popup.imgDesktop && popup.imgMobile) {
            link.classList.add('hide-on-tablet');
            img = `<picture>
               <source media="(min-width:1000px)" srcset="/image/view2/${popup.imgDesktop.id}">
               <img src="/image/view2/${popup.imgMobile.id}" style="width: 100%; height: 100%;">
           </picture>`;
        } else if (popup.imgDesktop && popup.imgTablet) {
            link.classList.add('hide-on-mobile');
            img = `<picture>
               <source media="(min-width:1000px)" srcset="/image/view2/${popup.imgDesktop.id}">
               <img src="/image/view2/${popup.imgTablet.id}" style="width: 100%; height: 100%;">
           </picture>`;
        } else if (popup.imgTablet && popup.imgMobile) {
            link.classList.add('hide-on-desktop');
            img = `<picture>
                <source media="(min-width:768px)" srcset="/image/view2/${popup.imgTablet.id}">
                <img src="/image/view2/${popup.imgMobile.id}" style="width: 100%; height: 100%;">
           </picture>`;
        } else if (popup.imgTablet) {
            link.classList.add('hide-on-desktop');
            link.classList.add('hide-on-mobile');
            img = `<picture>
                <img src="/image/view2/${popup.imgTablet.id}" style="width: 100%; height: 100%;">
           </picture>`;
        } else if (popup.imgMobile) {
            link.classList.add('hide-on-desktop');
            link.classList.add('hide-on-tablet');
            img = `<picture>
                <img src="/image/view2/${popup.imgMobile.id}" style="width: 100%; height: 100%;">
           </picture>`;
        } else if (popup.imgDesktop) {
            link.classList.add('hide-on-tablet');
            link.classList.add('hide-on-mobile');
            img = `<picture>
                <img src="/image/view2/${popup.imgDesktop.id}" style="width: 100%; height: 100%;">
           </picture>`;
        }
        if (!img) {
            return;
        }
        //close button
        const icon = document.createElement('i');
        const registerPopupInStorage = (popupId) => {
            try {
                let json = JSON.parse(localStorage.getItem('last-popup'));
                if (!Array.isArray(json)) {
                    localStorage.removeItem('last-popup');
                    json = []
                }
                const now = new Date();
                const current = json.filter(e => e?.id != popupId);
                current.push({
                    closeDate: now.toDateString(),
                    id: popupId
                })
                localStorage.setItem('last-popup', JSON.stringify(current));
            } catch (error) {
                localStorage.removeItem('last-popup');
            }
        }
        icon.innerHTML = 'close';
        icon.classList.add('material-icons', 'close');
        icon.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            link.classList.add('hide');
            registerPopupInStorage(popup.id);
        })

        link.innerHTML = img;
        link.addEventListener('click', (e) => {
            e.preventDefault()
            registerPopupInStorage(popup.id);
            window.location.href = popup.url;
        })
        link.append(icon);
        document.querySelector('body').append(link);
    }
}

new PopupController();
