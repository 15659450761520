var GVCommon = require('../common/gv-common');
var Autocomplete = require('./shell-core-autocomplete');

module.exports = {

  init: function() {
    var self = this;
    window.addEventListener('DOMContentLoaded', function() {
      self.cacheDom();
      self.getStations();
      self.bindEvents();
    }, false);

  },

  data: {
    sidebarInactiveClass: 'shell-sidebar-trigger-inactive',
    autocompleteActiveClass: 'shell-autocomplete-active'
  },

  bindEvents: function() {

    var self = this;

    [].forEach.call(self.$labelInputs, function(label) {
      label.addEventListener('click', function(e) {

        GVCommon.addUrlStatus('autoComplete', {autoComplete: true});

        self.openAutoCompleteInput(e);
      });
    });
  },

  removeMobileActiveLayout: function(e) {

    if (GVCommon.data.userDevice() != 'phone') return;

    var url = GVCommon.getVarUrl();

    if (url.indexOf('autoComplete=true') > -1) history.back();
  },

  openAutoCompleteInput: function(e) {

    if (GVCommon.data.userDevice() != 'phone') return;


    // Fix keyboard ios
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 100);

    e.target.parentElement.classList.add(this.data.autocompleteActiveClass);
    this.$appTriggerSidebar.classList.add(this.data.sidebarInactiveClass);
    e.target.focus();
  },

  getStations: function() {

    var self = this;

    self.cacheStationsArr(function() {

      window.selected = '';

      self.$inputsAutocomplete.forEach(function(input) {
        new Autocomplete({
          input: input,
          appTriggerSidebar: self.$appTriggerSidebar,
          fieldToCompare: 'label',
          wrapper: 'ul',
          data: window.stations,
          autocompleteClass: 'shell-autocomplete',
          autocompleteActiveClass: self.data.autocompleteActiveClass,
          sidebarInactiveClass: self.data.sidebarInactiveClass,
          itemClass: 'sal-item',
          itemFocusClass: 'sal-item-focus',
          itemHistoryClass: 'sal-item-history',
          onFocusout: function(e) {
            self.removeMobileActiveLayout(e);
          }
        });
      });
    });
  },

  // Retorna a lista de Rodoviárias, atribui a uma variável global, e logo em seguida chama o método de autocompletar.
  // Este método de autocompletar é chamado usando vez diretamente,
  // e depois com um setTimeout. Isso faz com que o localStorage criado seja melhor aproveitado
  cacheStationsArr: function(callback) {

    var self = this;
    var allStations = localStorage.getItem('allStations');
    var stationsCached = allStations ? JSON.parse(allStations) : [];

    if (stationsCached.length) {
      window.stations = stationsCached;
      callback();

    } else {

      var xhr = new XMLHttpRequest();

      xhr.open('GET', '/station/all');
      xhr.send();
      xhr.onload = function(data) {

        try {
          var response = data.currentTarget && data.currentTarget.response;
          if (window.isQuotaExceededStations || !response) callback();

          window.stations = JSON.parse(response);
          localStorage.setItem('allStations', JSON.stringify(data));
        } catch (e) {
          if (!self.isQuotaExceededError(e)) {
            GVCommon.log('warn', e);
          } else {
            window.isQuotaExceededStations = true;
          }
        }
        callback();
      };
    }
  },

  isQuotaExceededError: function(e) {
    return e && (e.number && e.number === -2147024882 /*IE8*/) ||
    (e.code && e.code === 22 /*Browsers Modernos*/) ||
    ((e.code && e.code === 1014) &&
    (e.name && e.name === 'NS_ERROR_DOM_QUOTA_REACHED') /*FF*/);
  },

  cacheDom: function() {

    this.$appTriggerSidebar = document.querySelector('.shell-sidebar-trigger');
    this.$inputsAutocomplete = document.querySelectorAll('.js-input-autocomplete');
    this.$labelInputs = document.querySelectorAll('.js-input-autocomplete');
  }
};
