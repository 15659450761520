var Validator = require("./dc-form-validator");

module.exports = {

    init: function() {
        this.months = {
            'jan': 0,
            'fev': 1,
            'mar': 2,
            'abr': 3,
            'mai': 4,
            'jun': 5,
            'jul': 6,
            'ago': 7,
            'set': 8,
            'out': 9,
            'nov': 10,
            'dez': 11
        };

        var validator = new Validator(this.getValidatorConfig());
        validator.validate();

        document.querySelector("#form-search").addEventListener("checkForm", function (e) {
            validator.validate();
        });

    },

    // getYearByMonth returns the ticket year given month input in human readable form
    getYearByMonth: function(selectedMonth) {
        today = (new Date());
        currentMonth = today.getMonth();
        currentYear = today.getFullYear();
        return currentMonth > selectedMonth ? currentYear + 1 : currentYear;
    },

    //apiAddapter formats the input date to the API expected date format
    apiAddapter: function(inputDate) {
        var sanitizedDate = inputDate.split(' ')[1];

        var month = this.months[sanitizedDate.split('/')[1]];
        var year = this.getYearByMonth(month);
        var day = sanitizedDate.split('/')[0];

        month = ++month > 9 ? "" + month : "0" + month;

        return day + '/' + month + '/' + year;
    },

    getValidatorConfig: function () {

        var self = this;

        return {
            form: "#form-search",
            listeners: ["keyup", "focusout", "change", "VALIDATE_SEARCH_FORM"],
            errorListeners: ["keyup"],
            rules: {
                from: function (input) {
                    return !!input.value;
                },
                to: function (input) {
                    return !!input.value;
                },
                whenGo: function (input) {
                    return !!input.value.length &&  /^^(seg|ter|qua|qui|sex|sab|dom),\s[0-3]{1}\d\/(jan|fev|mar|abr|mai|jun|jul|ago|set|out|nov|dez)?/.test(input.value);
                },
                whenBack: function (input) {
                    return !input.hasAttribute('required') || (!!input.value.length &&  /^^(seg|ter|qua|qui|sex|sab|dom),\s[0-3]{1}\d\/(jan|fev|mar|abr|mai|jun|jul|ago|set|out|nov|dez)?/.test(input.value))
                },
            },
            onError: function (input) {
                input.classList.add("is-error");
            },
            offError: function (input) {
                input.classList.remove("is-error");
            },
            onFormValid: function (form, submit) {
                submit.removeAttribute("disabled");
            },
            onFormInvalid: function (form, submit) {
                submit.setAttribute("disabled", "disabled");
            },
            onSubmit: function (form, submit) {
                form.dateGo.value = self.apiAddapter(form.humanDateGo.value.toLowerCase());
                if(form.humanDateBack.value)
                    form.dateBack.value = self.apiAddapter(form.humanDateBack.value.toLowerCase());
                submit.classList.add("loading");
                // Fix safari.
                if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                    setTimeout(function () {
                        form.submit();
                        submit.classList.remove("loading");
                    }, 500);
                }
                else {
                    form.submit();
                }
            }
        };
    }
};
