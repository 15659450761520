require('./shell');
var GVDatepicker = require('./components/datepicker/gv-datepicker');
var GVFillForm = require('./components/fill-form/gv-fill-form');
var GVTab = require('./components/tab/gv-tab');
var GVRadio = require('./components/radio/gv-radio');
var GVCommon = require('./components/common/gv-common');
var GVFormEvents = require('./components/form-events/gv-form-events');

/**
 * @description Scripts gerais de todas as páginas
 */

var GvApp = {
    data: {},

    components: {
        search: !!document.querySelector('body').getAttribute('data-search'),
        hasSearch: !!document.querySelector('.shell-search-from'),
        banner: !!document.querySelector('body').getAttribute('data-banner'),
    },

    init: function() {
        var datepickerParams = {
            ida: '.js-input-data-ida',
            idaVal: '.js-input-data-ida-val',
            idaPannel: '.js-datepicker-bkg-pannel-ida',
            volta: '.js-input-data-volta',
            voltaVal: '.js-input-data-volta-val',
            voltaPannel: '.js-datepicker-bkg-pannel-volta',
        };

        this.cacheDom();

        if (this.components.search || this.components.hasSearch) {
            GVFillForm.init();
            GVTab.init();
            GVRadio.init();
            GVDatepicker.init(datepickerParams);
            GVFormEvents.init();
            this.enableInvertSearchButton();
        }

        this.$body.addEventListener('scrollOutAboveTheFold', this.onScrollOutAboveThefold.bind(this));
        this.preloadImages();
        this.watchScroll();
        this.pageview();
    },

    pageview: function() {
        var search = document.location.search;

        if (search) {
            var ajax = new XMLHttpRequest();
            ajax.open('GET', '/t/api/pageview' + search, true);
            ajax.send();
        }
    },

    watchScroll: function() {
        var self = this;
        var lazyImagesLoaded;
        var aboveTheFold = window.innerHeight + 100;
        var scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (scroll >= aboveTheFold) {
            try {
                this.lazyLoadImages();
                lazyImagesLoaded = true;
            } catch (e) {}
        }

        window.onscroll = function(e) {
            scroll = window.pageYOffset || document.documentElement.scrollTop;

            // Lazyload das imagens
            if (!lazyImagesLoaded && scroll >= aboveTheFold) {
                try {
                    self.$body.dispatchEvent(window.scrollOutAboveTheFold);
                    lazyImagesLoaded = true;
                } catch (e) {}
            }
        };
    },

    onScrollOutAboveThefold: function(e) {
        this.lazyLoadImages();
    },

    enableInvertSearchButton: function() {
        var self = this;

        self.$invertSearchButton.addEventListener('click', function(e) {
            e.preventDefault();

            if (document.activeElement.nodeName == 'INPUT') return;

            e.currentTarget.firstElementChild.classList.toggle('is-inverted');

            var fromId = self.$inputSearchFrom.previousElementSibling.value;
            var fromLabel = self.$inputSearchFrom.value;
            var toId = self.$inputSearchTo.previousElementSibling.value;
            var toLabel = self.$inputSearchTo.value;

            self.$inputSearchFrom.value = toLabel;
            self.$inputSearchFrom.previousElementSibling.value = toId;
            self.$inputSearchTo.value = fromLabel;
            self.$inputSearchTo.previousElementSibling.value = fromId;
        });
    },

    preloadImages: function() {
        var imagesArr = [];

        if (GVCommon.data.userDevice() == 'phone') {
            imagesArr = ['/public/_v2/static/img/icons/baseline-chevron_left-24px.svg'];
        }

        imagesArr.forEach(function(img) {
            var link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = img;
            document.getElementsByTagName('head')[0].appendChild(link);
        });
    },

    lazyLoadImages: function() {
        var lazyImages = document.querySelectorAll('[data-lazy]');

        [].forEach.call(lazyImages, function(img) {
            img.setAttribute('src', img.getAttribute('data-lazy'));
            img.onload = function() {
                img.removeAttribute('data-lazy');
            };
        });
    },

    cacheDom: function() {
        this.$body = document.querySelector('body');
        this.$invertSearchButton = document.querySelector('.js-search-invert-button');
        this.$inputSearchFrom = document.querySelector('.js-input-search-from');
        this.$inputSearchTo = document.querySelector('.js-input-search-to');
        this.$inputSearchToSpan = document.querySelector('.shell-input-label-to');
    },
};

GvApp.init();
