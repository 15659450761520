import { LoadingComponent } from 'commons/ts/loading.component';
import { GvLoginComponents } from './interfaces/components.interface';
import { GvLoginSelectors } from './interfaces/selectors.interface';

declare global {
    interface Window {
        grecaptcha: any;
     }
}

export class GVLogin {

    private action: Function;

    private selectors: GvLoginSelectors = {
        register: {
            panel: '.gv-login .gl-panel-register',
            name: '.gv-login .gl-panel-register #name-register',
            email: '.gv-login .gl-panel-register #email-register',
            password: '.gv-login .gl-panel-register #password-register',
            confirmPassword: '.gv-login .gl-panel-register #password-confirmation',
            newsletter: '.gv-login .gl-panel-register #checkbox-newsletter',
            confirmPrivacy: '.gv-login .gl-panel-register #checkbox-privacy',
            btnBack: '.gv-login .gl-panel-register #btn-back-register',
            btnRegister: '.gv-login .gl-panel-register #btn-register',
            notification: '.gv-login .gl-panel-register #register-notification',
        },
        redefine: {
            panel: '.gv-login .gl-panel-redefine',
            email: '.gv-login .gl-panel-redefine #email-redefine',
            btnBack: '.gv-login .gl-panel-redefine #btn-back-redefine',
            btnRedefine: '.gv-login .gl-panel-redefine #btn-redefine',
            notification: '.gv-login .gl-panel-redefine #redefine-notification',
        },
        main: {
            panel: '.gv-login .gl-panel-login',
            btnFacebook: '.gv-login #btn-facebook',
            btnGoogle: '.gv-login #btn-google',
            email: '.gv-login #email',
            password: '.gv-login #password',
            btnLogin: '.gv-login #btn-login',
            btnRegister: '.gv-login #btn-goto-register',
            btnRedefine: '.gv-login #btn-goto-redefine',
            notification: '.gv-login #login-notification',
        },
        email: {
            panelDesktop: '.gv-login .gl-panel-email.gl-pe-desktop',
            panelMobile: '.gv-login .gl-panel-email.gl-pe-mobile',
            btnContinueWithoutLoginDesktop: '.gv-login .gl-panel-email.gl-pe-desktop .gl-btn',
            btnContinueWithoutLoginMobile: '.gv-login .gl-panel-email.gl-pe-mobile .gl-btn'
        },
        modal: '.gv-login-modal .gv-modal-fullscreen',
        closeButton: '.gv-login #btn-close'
    };

    private components: GvLoginComponents = {
        register: {
            panel: null,
            name: null,
            email: null,
            password: null,
            confirmPassword: null,
            newsletter: null,
            btnBack: null,
            btnRegister: null,
            notification: null,
            confirmPrivacy: null
        },
        redefine: {
            panel: null,
            email: null,
            btnBack: null,
            btnRedefine: null,
            notification: null,
        },
        main: {
            panel: null,
            btnFacebook: null,
            btnGoogle: null,
            email: null,
            password: null,
            btnLogin: null,
            btnRegister: null,
            btnRedefine: null,
            notification: null,
        },
        email: {
            panelDesktop: null,
            panelMobile: null,
            btnContinueWithoutLoginDesktop: null,
            btnContinueWithoutLoginMobile: null,
        },
        modal: null,
        closeButton: null,
        loading: new LoadingComponent('load', 'Carregando')
    };

    constructor() {
        try {
            this.cacheDom();
            this.checkInit();
        }
        catch (error) { }
    }
    /**
     * Métodos de UI
     */

    /**
     * Inicializa os objetos do DOM.
     * (deve possuir o mesmo nome na variavel 'selectors' e na variavel 'components').
     */
    private cacheDom(): void {
        for (const key in this.selectors) {
            if (!this.components.hasOwnProperty(key)) continue;

            if (typeof this.selectors[key] === 'object') {
                for (const subKey in this.selectors[key]) {
                    if (this.components[key].hasOwnProperty(subKey))
                        this.components[key][subKey] = document.querySelector(this.selectors[key][subKey]);
                }
                continue;
            }

            this.components[key] = document.querySelector(this.selectors[key]);
        }
    }

    private checkInit(): void {
        const ERROR_MSG: string = 'Login modal not found';

        if (!this.components.modal) {
            throw ERROR_MSG;
        }
    }

    setSuccessAction(action: Function): void {
        this.action = action;
    }
}
