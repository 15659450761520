var dayjs = require('dayjs');
require('dayjs/locale/pt-br');
dayjs.locale('pt-br');

// Preenche o form de busca baseado na busca anterior

module.exports = {
  init: function() {
    this.cacheDom();

    if (!this.$departureId || !this.$departureLabel) return;

    var lastSearch = this.getLastSearchInputData();
    var allRequiredFieldsHasData = this.fillInputFrom(lastSearch);
    allRequiredFieldsHasData = this.fillInputTo(lastSearch) && allRequiredFieldsHasData;
    allRequiredFieldsHasData = this.fillInputDate(lastSearch) && allRequiredFieldsHasData;

    if (allRequiredFieldsHasData) {
      setTimeout(function() {
        try {
          document.querySelector('#form-search').dispatchEvent(window.checkForm);
        } catch (e) {}
      }, 1000);
    }
  },

  // Autofill do Input "Saindo de Onde"
  fillInputFrom: function(lastSearch) {
    var id;
    if (lastSearch.lastGoId && (!this.$departureId.value || this.$departureId.value !== lastSearch.lastGoId)) {
      this.$departureId.value = lastSearch.lastGoId;
      id = true;
    }

    var label;
    if (lastSearch.lastGoLabel && (!this.$departureLabel.value || this.$departureLabel.value !== lastSearch.lastGoLabel)) {
      this.$departureLabel.value = lastSearch.lastGoLabel;
      this.$departureLabelSpan.textContent = lastSearch.lastGoLabel;
      label = true;
    }
    return id && label;
  },

  // Autofill do Input "Para onde"
  fillInputTo: function(lastSearch) {
    var id;
    if (!!lastSearch.lastBackId && (!this.$arrivalId.value || this.$arrivalId.value !== lastSearch.lastBackId)) {
      this.$arrivalId.value = lastSearch.lastBackId;
      id = true;
    }

    var label;
    if (!!lastSearch.lastBackLabel && (!this.$arrivalLabel.value || this.$arrivalLabel.value !== lastSearch.lastBackLabel)) {
      this.$arrivalLabel.value = lastSearch.lastBackLabel;
      this.$arrivalLabelSpan.textContent = lastSearch.lastBackLabel;
      label = true;
    }
    return id && label;
  },
  //transform from DD/MM/YYYY to dayjs
  parseDate: function(str) {
    if (str) {
      var sarr = str.split('/');
      return dayjs('' + sarr[2] + '-' + sarr[1] + '-' + sarr[0]);
    }
    return null;
  },

  // Autofill do Input de Data (Ida e Volta)
  fillInputDate: function(lastSearch) {
    var today = dayjs().startOf('day');
    var dateGoDate = this.parseDate(lastSearch.lastGoDate);
    var dateBackDate = this.parseDate(lastSearch.lastBackDate);

    if (dateGoDate && !dateGoDate.isValid()) {
      lastSearch.lastBackDate = '';
      lastSearch.lastGoDate = '';
      this.$dateBack.value = '';
      this.$dateGo.value = '';
      this.$humanDateBack.value = '';
      this.$humanDateGo.value = '';
      return;
    }

    if ((!this.$dateGo.value || this.$dateGo.value !== lastSearch.lastGoDate || (dateGoDate && dateGoDate.isBefore(today))) && dateGoDate) {
      if (dateGoDate.isBefore(today)) {
        dateGoDate = today;
        dateGoDate.add(1, 'd');
        lastSearch.lastGoDate = dateGoDate.format('DD/MM/YYYY');
      }
      this.$dateGo.value = lastSearch.lastGoDate;
      if (dateGoDate.isValid())
        this.$humanDateGo.value = dateGoDate
          .format('ddd, DD/MMM')
          .replace('á', 'a')
          .toLowerCase();
    }

    if (
      this.$dateBack &&
      (!this.$dateBack.value ||
      this.$dateBack.value !== lastSearch.lastBackDate ||
      (dateBackDate && dateBackDate.isBefore(dateGoDate)) ||
      dateBackDate.isSame(dateGoDate))
    ) {
      // backDateStillValid means semantic's validity as defined in isSameOrBefore, and date.isValid concerns to moment
      var backDateStillValid = !((dateBackDate && dateBackDate.isBefore(dateGoDate)) || lastSearch.lastBackDate === '');

      if (!backDateStillValid) {
        lastSearch.lastBackDate = '';
        this.$dateBack.value = '';
        this.$humanDateBack.value = '';
      } else if (dateBackDate && dateBackDate.isValid()) {
        this.$dateBack.value = lastSearch.lastBackDate;
        this.$humanDateBack.value = dateBackDate
          .format('ddd, DD/MMM')
          .replace('á', 'a')
          .toLowerCase();
      }


      if (this.$dateBack.value != '') {
        setTimeout(function() {
          try {
            document.querySelector('.js-trigger-back').dispatchEvent(window.autofillBackHistory);
            document.querySelector('.js-trigger-tab-back').dispatchEvent(window.autofillBackHistory);
          } catch (e) {}
        }, 1000);
      }
    }

    return !!this.$dateGo.value.length;
  },

  getLastSearchInputData: function() {
    let searchFromUrl = this.getSearchInputFromURLparam();
    if (!!searchFromUrl)
      return searchFromUrl;

    //Separa os local storage por landing page, para as rotas pesquisadas em uma nÃ£o aparecerem em outras.
    var companykey = this.$inputCompanykey && this.$inputCompanykey.value ? this.$inputCompanykey.value + '.' : '';

    return {
      lastGoId: localStorage.getItem(companykey + 'lastGoId'),
      lastGoLabel: localStorage.getItem(companykey + 'lastGoLabel'),
      lastBackId: localStorage.getItem(companykey + 'lastBackId'),
      lastBackLabel: localStorage.getItem(companykey + 'lastBackLabel'),
      lastGoDate: localStorage.getItem(companykey + 'lastGoDate'),
      lastBackDate: localStorage.getItem(companykey + 'lastBackDate')
    };
  },

  getSearchInputFromURLparam: function () {
      try {
        let urlParams = new URLSearchParams(window.location.search);
        let today = urlParams.get("today");
        let useToday = today === "" || today === "true";

        if (!useToday) {
            return null;
        }

        let date = new Date();

        return {
          lastGoId: document.body.dataset.origid,
          lastGoLabel: document.body.dataset.origlabel,
          lastBackId: document.body.dataset.destination,
          lastBackLabel: document.body.dataset.destinationlabel,
          lastGoDate: document.body.dataset.swap === 'true' ? null : `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`,
        }
      }
      catch (e) {
        return null;
      }
  },

  cacheDom: function() {
    this.$departureId = document.querySelector("[name='departure.id']");
    this.$arrivalId = document.querySelector("[name='arrival.id']");
    this.$arrivalLabel = document.querySelector("[name='arrival.label']");
    this.$arrivalLabelSpan = document.querySelector('.shell-input-label-to');
    this.$inputCompanykey = document.querySelector("[name='company.key']");
    this.$inputLabel = document.querySelectorAll('.shell-input-label');
    this.$departureLabelSpan = document.querySelector('.shell-input-label-from');
    this.$departureLabel = document.querySelector("[name='departure.label']");
    this.$dateGo = document.querySelector("[name='dateGo']");
    this.$humanDateGo = document.querySelector("[name='humanDateGo']");
    this.$dateBack = document.querySelector("[name='dateBack']");
    this.$humanDateBack = document.querySelector("[name='humanDateBack']");
  }
};
