var STORAGE_KEY = 'gv_stationSearchKey';
var MAX_HISTORY = 7;
var history = [];
var loaded = false;

function addServer(stationId) {

    var xhr = new XMLHttpRequest();

    xhr.open("PUT", "/user/search-history/" + stationId, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();
}

function save(value) {
    history = value;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
}

function addLocal(history, stationId) {

    history = history.filter(function (value) {
        return value !== stationId;
    });

    if (history.length >= MAX_HISTORY) history.pop();

    history.unshift(stationId);
    save(history);
    return history;
}

function loadFromLocalStorage() {

    var historyStr = localStorage.getItem(STORAGE_KEY);
    if (!historyStr) return [];
    return JSON.parse(historyStr);
}

function loadFromApi(callback) {

    var xhr = new XMLHttpRequest();

    xhr.open("GET", "/user/search-history");
    xhr.send();
    xhr.onload = function (response) {

        if (this.status === 200) {
            loaded = true;
            loadFromApiSuccess(response); // Done
        }
        callback(history);
    };
}

function loadFromApiSuccess(response) {

    var responseData = JSON.parse(response.target.response);
    var data = Array.isArray(responseData) ? responseData : [];

    if (data.length) {
        history = data.map(function (item) {
            return item.busStationId;
        });
        save(history);
    }
}

module.exports = function() {

    this.load = function (callBack) {

        if (loaded) return;

        if (localStorage.getItem(STORAGE_KEY)) {
            history = loadFromLocalStorage();
            callBack(history);
        }
        else {
            loadFromApi(function(history) {
                callBack(history);
            });
        }
    };

    this.add = function (history, stationId) {
        stationId = Number(stationId);
        if (isNaN(stationId) || stationId == null) {
            return;
        }

        addServer(stationId);
        return addLocal(history, stationId);
    };

    this.getHistory = function () {
        return history;
    };

    this.weightStations = function (stations, history) {

        var newStations = stations;
        
        newStations.forEach(function (item) {
            var historyIndex = history ? history.indexOf(Number(item.value)) : -1;
            if (historyIndex > -1) {
                item.historyOrder = MAX_HISTORY - historyIndex;
            }
            else {
                item.historyOrder = -1;
            }
        });
        return newStations;
    };
};
